<template>
  <div class="win">
    <div class="mains">
      <div :style="Height">
        <div class="main" :style="iconmain">
          <div class="logo">
            <img
                :style="icon"
                v-if="start"
                src="../assets/image/home/home1.png"
            />
            <img
                :style="icon"
                v-else
                src="../assets/image/home/home2.png"
            />
          </div>
          <div class="canvasNumber">
            <span v-bind:class="{ red: !start}">{{ creationNumber }}</span>
            <span v-if="h&&m&&s" class="black" v-bind:class="{ red: !start}">{{ h }}:{{ m }}:{{ s }}</span>
          </div>
        </div>
      </div>
      <img
          :style="icon"
          v-if="start"
          class="bg"
          src="../assets/image/home/bg-bule.png"
      />
      <img
          :style="icon"
          v-else
          class="bg"
          ref="iconRed"
          src="../assets/image/home/bg-red.png"
      />
      <div class="next" @click="next">下一页</div>
    </div>
  </div>
</template>

<script>
const {clientHeight} = document.documentElement
import {initHome} from "../api/home";

export default {
  data() {
    return {
      Height: '',
      start: false,
      icon: '',
      iconmain:'',
      creationNumber: 0,
      h: '',
      m: '',
      s: '',
      timeEnd: '',
    }
  },
  beforeDestroy() {
    clearInterval(this.time)
  },
  mounted() {
    setTimeout(()=>{
      let offsetHeight=this.$refs.iconRed.offsetHeight
      this.Height = 'height:' + (clientHeight - offsetHeight) + 'px'
      this.clientHeight = clientHeight - offsetHeight
      // this.iconmain = `margin-top: -120px;`
    },300)

      initHome().then(res => {
        let timeEnd = res.timeEnd
        let creationNumber = this.getNunber(res.creationNumber)
        this.$store.state.creationNumber = creationNumber
        this.creationNumber = creationNumber
        this.timeEnd = timeEnd
        this.countTime()
      })

    this.time = setInterval(() => {
      this.countTime()
    }, 1000);

      setTimeout(() => {
        let SidNumber = this.$route.query.SidNumber

        if (SidNumber) {
          this.$router.push({
            path: '/lookwork',
            query: {
              SidNumber
            }
          })
        } else {
          // let ins = this.getRandom(0, 2)
          // this.start = true
          // if (ins == 0) {
          //
          // } else {
          //   this.start = false
          // }
        }
      }, 500)
  },
  methods:{
    next(){
      this.$parent.upperLogin()
    },
    countTime() {
      //获取当前时间
      let date = new Date();
      let now = date.getTime();
      //设置截止时间
      let endDate = new Date(this.timeEnd);
      let end = endDate.getTime();
      //时间差
      let differTime = end - now;
      //定义变量,h,m,s保存倒计时的时间
      let h, m, s;
      if (differTime >= 0) {
        h = Math.floor(differTime / 1000 / 60 / 60);
        m = Math.floor(differTime / 1000 / 60 % 60);
        s = Math.floor(differTime / 1000 % 60);
        this.h = h < 10 ? ("0" + h) : h;
        this.m = m < 10 ? ("0" + m) : m;
        this.s = s < 10 ? ("0" + s) : s;
      } else {
        this.h = "00";
        this.m = "00";
        this.s = "00";
      }
    },
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    getNunber(str) {
      if (str < 10) {
        return '0000' + str
      } else if (str < 100) {
        return '000' + str
      } else if (str < 1000) {
        return '00' + str
      } else if (str < 10000) {
        return '0' + str
      }
    },
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: intext;
  src: url('https://hz-mayi.nos-eastchina1.126.net/2021-11-6/VonwaonBitmap-12px.ttf');
}

.win{
  width: 100%;
  height: 100%;
}
.mains {
  width: 100%;
  height: 100%;
  position: relative;

  .main {
    width: 220px;
    height: 240px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -110px;
    transform: translate(0, -100%);
    border:none;
    .canvasNumber {
      font-family: intext;
      font-size: 17px;
      color: #1989fa;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      border:none;

      .black {
        color: #020202;
      }
    }

    .logo {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      .imageUrl {
        width: 44px;
        height: 30px;
        position: absolute;
        right: 19px;
        top: 16px;
        background-size: 100% 100%;
      }
    }

  }

  .red {
    color: #af1f24;
  }
  .bg {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .next{
    position: absolute;
    right: 30px;
    bottom: 50px;
    color: #B6FFF3;
    width: 80px;
    height: 30px;
    line-height: 30px;
    border: 2px solid #B6FFF3;
    border-radius: 5px;
    text-align: center;
  }
}
</style>
